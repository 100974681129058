import { themes } from 'mdx-deck';

export default {
    ...themes.code,
    ...themes.prism,
    colors: {
        ...themes.code.colors,
        background: '#fafafa',
    }
};
