
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import myTheme from './theme';

export const theme = myTheme;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Head = makeShortcode("Head");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Head mdxType="Head">
  <title>DNSSEC</title>
    </Head>
    <h1>{`DNSSEC`}</h1>
    <p>{`Domain Name System Security Extensions`}</p>
    <hr></hr>
    <h1>{`Built with `}<a parentName="h1" {...{
        "href": "https://github.com/jxnblk/mdx-deck"
      }}>{`MDX Deck`}</a></h1>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;